import React, { FC, memo } from 'react'

import { ISVGProps } from '@svg/types'

interface IProps extends ISVGProps {}

const HuaweiStoreSVG: FC<IProps> = ({ className }) => (
  <svg
    className={className || ''}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_429_1917" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <path d="M0 0H24V24H0V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_429_1917)">
      <path
        d="M18.333 1C20.911 1 23 3.09 23 5.667V18.333C23 20.911 20.91 23 18.333 23H5.667C3.089 23 1 20.91 1 18.333V5.667C1 3.089 3.09 1 5.667 1H18.333ZM9.124 11.826H8.53V13.475L8.525 13.587C8.489 13.983 8.258 14.193 7.865 14.193C7.433 14.193 7.195 13.936 7.195 13.455V11.828H6.6V13.475L6.606 13.623C6.659 14.341 7.108 14.75 7.856 14.75C8.662 14.75 9.124 14.276 9.124 13.451V11.826ZM3.843 11.826H3.248V14.706H3.843V13.536H5.185V14.706H5.779V11.826H5.185V12.988H3.843V11.826ZM14.734 11.824H14.249L13.584 13.804L12.937 11.826H12.305L13.325 14.705H13.817L14.484 12.813L15.15 14.705H15.646L16.664 11.825H16.048L15.399 13.805L14.734 11.825V11.824ZM11.237 11.824H10.717L9.427 14.704H10.033L10.283 14.146L10.3 14.104H11.64L11.903 14.704H12.527L11.247 11.842L11.237 11.825V11.824ZM19.298 11.827H17.143V14.703H19.355V14.179H17.729V13.466H18.809V12.941H17.729V12.352H19.299V11.827H19.298ZM20.648 11.825H20.062V14.701H20.648V11.825ZM10.968 12.554L11.393 13.518L11.421 13.584H10.523L10.551 13.518L10.969 12.554H10.968ZM17.558 2.438C17.212 2.438 16.933 2.718 16.933 3.062C16.933 3.205 16.982 3.337 17.064 3.442C15.847 5.164 13.973 6.17 11.936 6.173C9.906 6.173 8.046 5.188 6.828 3.48C6.928 3.37 6.99 3.223 6.99 3.06L6.983 2.968C6.937 2.668 6.677 2.438 6.363 2.438C6.017 2.438 5.737 2.718 5.738 3.062C5.738 3.4 6.008 3.674 6.343 3.685C7.549 5.705 9.596 6.697 11.935 6.697C14.266 6.693 16.328 5.695 17.535 3.685H17.559C17.905 3.685 18.185 3.406 18.185 3.06L18.178 2.968C18.133 2.668 17.872 2.438 17.558 2.438Z"
        fill="white"
      />
    </g>
  </svg>
)

export default memo(HuaweiStoreSVG)
