import React, { FC, memo } from 'react'
import { ISVGProps } from './types'

interface IProps extends ISVGProps {}

const EqualizerSVG: FC<IProps> = ({ className }) => (
  <svg
    className={className || ''}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20H14V4H10V20ZM4 20H8V12H4V20ZM16 9V20H20V9H16Z"
      fill="#5C6871"
    />
    <mask id="mask0_429_2023" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20H14V4H10V20ZM4 20H8V12H4V20ZM16 9V20H20V9H16Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_429_2023)"></g>
  </svg>
)

export default memo(EqualizerSVG)
