import React, { FC, memo } from 'react'

import { ISVGProps } from '@svg/types'

interface IProps extends ISVGProps {}

const ViberSVG: FC<IProps> = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="#7360F2"
    />
    <path
      d="M23.0049 9.35756C22.5457 8.93196 20.6865 7.58236 16.5481 7.56556C16.5481 7.56556 11.6649 7.26876 9.26812 9.45276C7.94092 10.78 7.47612 12.7176 7.42572 15.12C7.37532 17.5224 7.31372 22.0248 11.6537 23.2456V25.1104C11.6537 25.1104 11.6537 25.8664 12.1241 26.0176C12.7233 26.208 13.0761 25.6312 13.6529 25.0152C13.9665 24.6736 14.4033 24.1752 14.7281 23.7944C16.5746 23.9669 18.4368 23.8306 20.2385 23.3912C20.8377 23.1952 24.2313 22.764 24.7857 18.256C25.3737 13.6136 24.5281 10.6792 23.0049 9.35756ZM23.5089 17.9256C23.0385 21.6832 20.2889 21.9184 19.7849 22.0808C18.2347 22.4629 16.6344 22.6008 15.0417 22.4896C15.0417 22.4896 13.1601 24.7296 12.5721 25.3456C12.5058 25.424 12.4057 25.4657 12.3033 25.4576C12.2025 25.4296 12.1745 25.312 12.1745 25.1384C12.1745 24.9648 12.1745 22.0416 12.1745 22.0416C8.50092 21.0168 8.71372 17.1864 8.75852 15.1816C8.80332 13.1768 9.17852 11.5304 10.2985 10.4216C12.3089 8.60156 16.4585 8.87036 16.4585 8.87036C19.9585 8.87036 21.6385 9.93996 22.0249 10.2928C23.3297 11.4016 23.9849 14.0448 23.5089 17.9256Z"
      fill="white"
    />
    <path
      d="M18.2505 15.2487C18.1249 15.2489 18.0213 15.1502 18.0153 15.0247C18.0451 14.7006 17.9324 14.3796 17.7066 14.1452C17.4808 13.9109 17.1643 13.7863 16.8393 13.8039C16.7094 13.7962 16.6104 13.6846 16.6181 13.5547C16.6258 13.4248 16.7374 13.3258 16.8673 13.3335C17.3115 13.3198 17.7407 13.4946 18.049 13.8146C18.3573 14.1347 18.516 14.5702 18.4857 15.0135C18.4887 15.0759 18.4668 15.137 18.4248 15.1832C18.3828 15.2294 18.3241 15.257 18.2617 15.2599L18.2505 15.2487Z"
      fill="white"
    />
    <path
      d="M19.4547 15.6519C19.3923 15.6504 19.3331 15.6242 19.29 15.5791C19.2469 15.5339 19.2236 15.4735 19.2251 15.4111C19.2677 14.6847 19.0153 13.9719 18.5251 13.4343C17.9899 12.88 17.2571 12.5599 16.4867 12.5439C16.3951 12.5498 16.3085 12.502 16.2648 12.4213C16.221 12.3407 16.2282 12.242 16.2831 12.1685C16.3379 12.095 16.4306 12.0601 16.5203 12.0791C17.4097 12.1033 18.2541 12.4753 18.8723 13.1151C19.4346 13.7393 19.7265 14.5609 19.6843 15.3999C19.6906 15.4646 19.6693 15.5289 19.6255 15.5769C19.5817 15.625 19.5197 15.6522 19.4547 15.6519Z"
      fill="white"
    />
    <path
      d="M20.6809 16.1335C20.551 16.1335 20.4457 16.0282 20.4457 15.8983C20.5122 14.6517 20.0556 13.4338 19.1857 12.5383C18.358 11.7298 17.2401 11.2879 16.0833 11.3119C15.9534 11.3119 15.8481 11.2066 15.8481 11.0767C15.8481 10.9468 15.9534 10.8415 16.0833 10.8415C17.3615 10.82 18.5953 11.3099 19.5105 12.2023C20.462 13.1826 20.9683 14.5111 20.9105 15.8759C20.9105 16.0058 20.8052 16.1111 20.6753 16.1111L20.6809 16.1335Z"
      fill="white"
    />
    <path
      d="M16.9009 18.1496C17.0888 18.1636 17.2732 18.0939 17.4049 17.9592L17.7521 17.5224C17.9975 17.2523 18.4017 17.198 18.7097 17.3936C19.0068 17.565 19.2947 17.7519 19.5721 17.9536C19.8353 18.1496 20.3673 18.592 20.3673 18.5976C20.6268 18.8075 20.6871 19.1788 20.5073 19.46C20.3167 19.791 20.0748 20.0897 19.7905 20.3448C19.5854 20.5311 19.3305 20.6537 19.0569 20.6976C19.0217 20.7037 18.9857 20.7037 18.9505 20.6976C18.8461 20.6992 18.7421 20.684 18.6425 20.6528C17.9446 20.4115 17.2728 20.1 16.6377 19.7232C16.0466 19.3989 15.4845 19.0241 14.9577 18.6032C14.6931 18.3938 14.4407 18.1695 14.2017 17.9312H14.1737C13.9369 17.6889 13.7126 17.4347 13.5017 17.1696C13.0827 16.6415 12.708 16.0795 12.3817 15.4896C12.0048 14.8553 11.6951 14.1834 11.4577 13.4848C11.4246 13.3835 11.4076 13.2777 11.4073 13.1712C11.4039 13.1358 11.4039 13.1002 11.4073 13.0648C11.4504 12.7926 11.5732 12.5393 11.7601 12.3368C11.9755 12.0106 12.2422 11.7213 12.5497 11.48C12.8326 11.2995 13.2059 11.3597 13.4177 11.62C13.4177 11.62 13.8657 12.18 14.0561 12.4152C14.2465 12.6504 14.4817 13.0592 14.6161 13.2776C14.8065 13.5897 14.7503 13.9928 14.4817 14.2408L14.0449 14.5824C13.91 14.7162 13.8404 14.9025 13.8545 15.092C14.288 16.5605 15.434 17.7107 16.9009 18.1496Z"
      fill="white"
    />
  </svg>
)

export default memo(ViberSVG)
