// extracted by mini-css-extract-plugin
export var h1_bold = "Typography-module--h1_bold--oWleh";
export var h2_bold = "Typography-module--h2_bold--6ut0v";
export var h2_medium = "Typography-module--h2_medium--uj+oj";
export var h3_bold = "Typography-module--h3_bold--21ca-";
export var h3_medium = "Typography-module--h3_medium--KlUUC";
export var h4_bold = "Typography-module--h4_bold--prQ0a";
export var h4_medium = "Typography-module--h4_medium--6muT6";
export var h5_bold = "Typography-module--h5_bold--NZClG";
export var h5_medium = "Typography-module--h5_medium--lh3gs";
export var h6_bold = "Typography-module--h6_bold--yRmjj";
export var h6_medium = "Typography-module--h6_medium--W7sfC";
export var body_1 = "Typography-module--body_1--QfI56";
export var body_2 = "Typography-module--body_2--f9uHF";
export var body_3 = "Typography-module--body_3--d2KzJ";