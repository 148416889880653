import React, { FC, memo } from 'react'

import { ISVGProps } from '@svg/types'

interface IProps extends ISVGProps {}

const FacebookSVG: FC<IProps> = ({ className }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      className={className || ''}
      id="mask0_429_1987"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <path d="M0.000976562 0.000976562H31.9996V31.805H0.000976562V0.000976562Z" fill="white" />
    </mask>
    <g mask="url(#mask0_429_1987)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16C32 7.164 24.836 0 16 0C7.164 0 0 7.164 0 16C0 23.9867 5.85067 30.6053 13.5 31.8053V20.6267H9.43733V16H13.5V12.4747C13.5 8.46533 15.8893 6.25067 19.544 6.25067C21.2933 6.25067 23.1253 6.56267 23.1253 6.56267V10.5H21.1067C19.1187 10.5 18.4987 11.7333 18.4987 12.9987V16H22.936L22.2267 20.6253H18.4987V31.8053C26.1493 30.6053 32 23.9867 32 16Z"
        fill="#1877F2"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2282 20.6254L22.9375 16.0001H18.5002V12.9987C18.5002 11.7334 19.1202 10.5001 21.1082 10.5001H23.1255V6.56273C23.1255 6.56273 21.2935 6.25073 19.5442 6.25073C15.8895 6.25073 13.5002 8.46407 13.5002 12.4747V16.0001H9.4375V20.6254H13.5002V31.8054C14.3135 31.9334 15.1495 32.0001 16.0002 32.0001C16.8508 32.0001 17.6855 31.9334 18.5002 31.8054V20.6267H22.2282"
      fill="white"
    />
  </svg>
)

export default memo(FacebookSVG)
