import React, { FC } from 'react'
import clsx from 'clsx'

import * as styles from './Typography.module.scss'
import { TypographyType } from './types'

interface IProps {
  className?: string
  type: TypographyType
  component?: 'button' | 'div' | 'span' | 'h1' | 'h2' | 'h3' | 'p' | 'li'
  onClick?(): void
  html?: string
}

const Typography: FC<IProps> = ({
  children,
  type,
  component: Component = 'span',
  className = '',
  html,
  ...otherProps
}) => {
  const props = {
    ...otherProps,
    className: clsx(className, {
      [styles[type || '']]: type,
    }),
  }

  return html ? (
    <Component {...props} dangerouslySetInnerHTML={{ __html: html }} />
  ) : (
    <Component {...props}>{children}</Component>
  )
}

export default Typography
