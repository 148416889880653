// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--iTLBh";
export var nav = "Footer-module--nav--al0wx";
export var list = "Footer-module--list--4PdPQ";
export var elem = "Footer-module--elem--f6HGx";
export var link = "Footer-module--link--UaI6A";
export var docs = "Footer-module--docs--cGozQ";
export var icon = "Footer-module--icon--eynq+";
export var note = "Footer-module--note--058pQ";
export var subscription = "Footer-module--subscription--Iib-r";
export var bottom = "Footer-module--bottom--yKSNI";
export var stores = "Footer-module--stores--cAwfn";
export var store = "Footer-module--store--CH3we";
export var storeLink = "Footer-module--storeLink--leflS";
export var storeIcon = "Footer-module--storeIcon--jpkxe";
export var socials = "Footer-module--socials--38jgd";
export var social = "Footer-module--social--qVP1g";
export var storeText = "Footer-module--storeText--BG9mX";
export var address = "Footer-module--address--+zkW7";