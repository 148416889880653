import React, { memo } from 'react'

import Container from '@ui/container'
import Typography from '@ui/typography'
import useWindowDimensions from '@hooks/useWindowDimensions'

import { nav, socials, stores } from './Footer.constants'

import * as styles from './Footer.module.scss'
import { personalLink, politicLink } from '@utils/constants'

const Footer = () => {
  const { isMobile } = useWindowDimensions()

  const getSortedNav = () => {
    const property = isMobile ? 'mobileIndex' : 'desktopIndex'
    return [...nav].sort((a, b) => a[property] - b[property])
  }

  return (
    <footer className={styles.footer}>
      <Container>
        <nav className={styles.nav}>
          <ul className={styles.list}>
            {getSortedNav().map(({ link, text, icon: IconSVG }, index) => (
              <li className={styles.elem} key={index}>
                <a href={link} className={styles.link} target="_blank">
                  {IconSVG && <IconSVG className={styles.icon} />}
                  <Typography type="body_2">{text}</Typography>
                </a>
              </li>
            ))}
          </ul>
        </nav>

        <Typography type="body_3" component="div" className={styles.note}>
          *Оплата производится в белорусских рублях по курсу НБ РБ.
        </Typography>

        <Typography type="body_3" component="div" className={styles.subscription}>
          © Kufar — крупнейшая площадка объявлений Беларуси по данным gemiusAudience, июнь 2020.
          Использование ресурса Kufar означает согласие с{' '}
          <a href={personalLink} className={styles.docs} target="_blanc">
            Пользовательским соглашением
          </a>{' '}
          и{' '}
          <a href={politicLink} className={styles.docs} target="_blank">
            Политикой конфиденциальности.
          </a>{' '}
          <span className={styles.address}>
            ООО «Адевинта», УНП 191767445, 220030, г. Минск, ул. Немига, 5, помещение 77. Режим
            работы: 10.00 - 18.00, Пн-Пт.
          </span>
        </Typography>

        <div className={styles.bottom}>
          <ul className={styles.stores}>
            {stores.map(({ icon: IconSVG, link, text }, index) => (
              <li className={styles.store} key={index}>
                <a href={link} className={styles.storeLink} target="_blank">
                  <IconSVG className={styles.storeIcon} />
                  <Typography className={styles.storeText} type="body_3">
                    {text}
                  </Typography>
                </a>
              </li>
            ))}
          </ul>

          <ul className={styles.socials}>
            {socials.map(({ link, icon: IconSVG }, index) => (
              <li className={styles.social} key={index}>
                <a href={link} target="_blank">
                  <IconSVG />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </footer>
  )
}

export default memo(Footer)
