import AppleSVG from '@svg/store/Apple'
import GoogleSVG from '@svg/store/Google'
import HuaweuiSVG from '@svg/store/Huawei'
import Equalaizer from '@svg/Equalaizer'
import TelegramSVG from '@svg/social/Telegram'
import VKSVG from '@svg/social/VK'
import FacebookSVG from '@svg/social/Facebook'
import InstagramSVG from '@svg/social/Instagram'
import YoutubeSVG from '@svg/social/Youtube'
import OKSVG from '@svg/social/OK'
import ViberSVG from '@svg/social/Viber'

export const nav = [
  {
    icon: Equalaizer,
    link: 'https://business.kufar.by/',
    text: 'Реклама',
    desktopIndex: 1,
    mobileIndex: 1,
  },
  {
    link: 'https://dostavka.kufar.by/',
    text: 'Доставка',
    desktopIndex: 2,
    mobileIndex: 2,
  },
  {
    link: 'https://rassrochka.kufar.by/',
    text: 'Рассрочка',
    desktopIndex: 3,
    mobileIndex: 5,
  },
  {
    link: 'https://safety.kufar.by/buyers',
    text: 'Советы по безопасности',
    desktopIndex: 4,
    mobileIndex: 3,
  },
  {
    link: 'https://www.kufar.by/rules.htm',
    text: 'Правила',
    desktopIndex: 5,
    mobileIndex: 4,
  },
  {
    link: 'https://support.kufar.by/hc/',
    text: 'Помощь',
    desktopIndex: 6,
    mobileIndex: 7,
  },
  // {
  //   link: '#',
  //   text: 'Выбрать регион',
  //   desktopIndex: 7,
  //   mobileIndex: 6,
  // },
  {
    link: 'https://careers.smartrecruiters.com/Adevinta/kufar',
    text: 'Вакансии',
    desktopIndex: 8,
    mobileIndex: 8,
  },
]

export const stores = [
  {
    icon: AppleSVG,
    text: 'Прыкладанне Kufar у App Store',
    link: 'https://apps.apple.com/by/app/kufar-besplatnye-ob-avlenia/id1149312972?utm_source=kufar&utm_medium=app_icon&utm_campaign=bottom_app_icon'
  },
  {
    icon: GoogleSVG,
    text: 'Прыкладанне Kufar у Google Play',
    link: 'https://play.google.com/store/apps/details?id=se.scmv.belarus&utm_source=kufar&utm_medium=app_icon&utm_campaign=bottom_app_icon'
  },
  {
    icon: HuaweuiSVG,
    text: 'Прыкладанне Kufar у AppGallery',
    link: 'https://appgallery.huawei.com/#/app/C102385255?utm_source=kufar&utm_medium=app_icon&utm_campaign=bottom_app_icon'
  },
]

export const socials = [
  {
    icon: TelegramSVG,
    link: 'https://t.me/spasiboKufar',
  },
  {
    icon: VKSVG,
    link: 'https://vk.com/kufar.official',
  },
  {
    icon: FacebookSVG,
    link: 'https://www.facebook.com/kufar.official/?fref=ts',
  },
  {
    icon: InstagramSVG,
    link: 'https://www.instagram.com/re.kufar/',
  },
  {
    icon: YoutubeSVG,
    link: 'https://www.youtube.com/channel/UCSc8YunZJe-wkf5rQ3P4cXA',
  },
  {
    icon: OKSVG,
    link: 'https://ok.ru/kufar.by',
  },
  {
    icon: ViberSVG,
    link: 'https://invite.viber.com/?g2=AQAAGl9I0gcmF0vM6pB%2FfH0hf5ow3vEDGzGXXvCkrtQ7j0ZuUu9KMuW8K1dyp3LX',
  },
]