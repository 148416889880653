import React, { FC, memo } from 'react'

import { ISVGProps } from '@svg/types'

interface IProps extends ISVGProps {}

const OKSVG: FC<IProps> = ({ className }) => (
  <svg
    className={className || ''}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#ED812B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.231 18.4632C20.5198 18.0038 20.4153 17.3557 19.9949 17.0069C19.6103 16.6605 18.9951 16.6232 18.5623 16.9058C17.1087 17.7984 15.1932 17.9036 13.6515 17.1628C13.3084 17.0129 13.0048 16.7434 12.6146 16.7186C12.1089 16.6715 11.5972 16.983 11.4189 17.4505C11.2046 17.9403 11.4084 18.555 11.8653 18.84C12.5896 19.2976 13.4045 19.6026 14.2407 19.7966C13.5065 20.5187 12.7714 21.2377 12.0353 21.958C11.745 22.2182 11.5793 22.61 11.6402 22.996C11.707 23.5729 12.2721 24.0352 12.8632 23.998C13.2357 23.9923 13.5563 23.7759 13.7974 23.5147C14.489 22.8389 15.171 22.1535 15.874 21.4884C16.5955 22.2087 17.3297 22.9173 18.0574 23.6328C18.357 23.9446 18.8337 24.0803 19.2541 23.9509C19.7451 23.8158 20.1108 23.34 20.1018 22.8389C20.1089 22.4973 19.937 22.1768 19.6851 21.9478C18.9521 21.2318 18.2197 20.5151 17.4899 19.7966C18.1632 19.6407 18.821 19.413 19.431 19.0904C19.7305 18.9303 20.0576 18.7673 20.231 18.4632ZM14.2033 12.4806C14.2525 11.8111 14.7715 11.2109 15.4331 11.0434C15.9747 10.8967 16.5853 11.0443 16.994 11.4205C17.3829 11.7663 17.5875 12.2982 17.5236 12.8097C17.4685 13.3505 17.1087 13.8472 16.6125 14.0883C16.0931 14.3474 15.435 14.311 14.9499 13.993C14.4435 13.6731 14.1406 13.0688 14.2033 12.4806ZM15.4003 16.5535C16.5303 16.6879 17.7115 16.3242 18.553 15.5718C19.3985 14.8429 19.9027 13.7518 19.9021 12.6488C19.9157 11.5174 19.4001 10.3956 18.5277 9.65538C17.6318 8.86747 16.3612 8.51838 15.1768 8.72527C13.7238 8.95929 12.4601 10.0417 12.0211 11.4172C11.6665 12.4872 11.8075 13.7029 12.4066 14.6625C13.0375 15.6994 14.1737 16.4253 15.4003 16.5535Z"
      fill="white"
    />
  </svg>
)

export default memo(OKSVG)
