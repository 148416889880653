export const links = [
  {
    text: 'Продажа',
    link: 'https://re.kufar.by/l/minsk/kupit/kvartiru'
  },
  {
    text: 'Аренда',
    link: 'https://re.kufar.by/l/minsk/snyat/kvartiru-dolgosrochno'
  },
  {
    text: 'Новостройки',
    link: 'https://re.kufar.by/l/minsk/novostrojki'
  },
  {
    text: 'Коммерческая',
    link: 'https://re.kufar.by/l/minsk/snyat/kommercheskaya'
  },
]

export const linkMain = 'https://re.kufar.by/'