import React, { FC, memo } from 'react'

import { ISVGProps } from '@svg/types'

interface IProps extends ISVGProps {}

const TelegramSVG: FC<IProps> = ({ className }) => (
  <svg
    className={className || ''}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#459FDE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7212 17.5175L7.26494 16.2335C6.91389 16.1025 6.91084 15.6092 7.26089 15.4748L21.4457 10.0279C21.7464 9.91213 22.0567 10.1744 21.9912 10.4887L19.4456 22.677C19.3856 22.9648 19.0467 23.0953 18.8074 22.9222L15.3325 20.4109C15.1215 20.2584 14.8338 20.2666 14.6319 20.4304L12.7053 21.9938C12.4816 22.1758 12.1447 22.0744 12.0591 21.8001L10.7212 17.5175ZM19.8005 12.0197L12.3563 16.3776C12.071 16.545 11.9366 16.8725 12.0288 17.1791L12.8337 19.8672C12.8908 20.0577 13.1817 20.0381 13.2094 19.8413L13.4187 18.3672C13.4582 18.0897 13.5975 17.834 13.8134 17.6429L19.9571 12.2149C20.072 12.1136 19.9343 11.9417 19.8005 12.0197Z"
      fill="white"
    />
  </svg>
)

export default memo(TelegramSVG)
