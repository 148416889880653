import React, { FC } from 'react'
import Header from '@components/header'
import Footer from '@components/footer'
import './reset.css'
import './Layout.css'
import clsx from 'clsx'

interface IProps {
  center?: boolean
}

const Layout: FC<IProps> = ({ children, center }) => (
  <div className="layout">
    <Header />
    <div
      className={clsx('layout__content', {
        ['layout__content_center']: center,
      })}
    >
      {children}
    </div>
    <Footer />
  </div>
)

export default Layout
