import React, { FC, memo } from 'react'

import { ISVGProps } from '@svg/types'

interface IProps extends ISVGProps {}

const VKSVG: FC<IProps> = ({ className }) => (
  <svg
    className={className || ''}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16Z"
      fill="#2787F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.60644 11.0667H7.61577C7.04644 11.0667 6.93311 11.344 6.93311 11.6507C6.93311 12.1973 7.60777 14.9067 10.0758 18.4907C11.7211 20.9413 14.0398 22.2667 16.1491 22.2667C17.4158 22.2667 17.5718 21.972 17.5718 21.464V19.6147C17.5718 19.024 17.6918 18.908 18.0918 18.908C18.3878 18.908 18.8931 19.06 20.0744 20.2413C21.4251 21.6387 21.6478 22.2667 22.4078 22.2667H24.3971C24.9664 22.2667 25.2504 21.972 25.0864 21.3907C24.9078 20.8107 24.2624 19.9693 23.4064 18.972C22.9438 18.404 22.2464 17.792 22.0371 17.4866C21.7411 17.0933 21.8251 16.9173 22.0371 16.568C22.0371 16.568 24.4624 13.0267 24.7158 11.8253C24.8424 11.388 24.7158 11.0667 24.1131 11.0667H22.1224C21.6158 11.0667 21.3824 11.344 21.2558 11.6507C21.2558 11.6507 20.2424 14.208 18.8091 15.8693C18.3451 16.3493 18.1344 16.5027 17.8811 16.5027C17.7544 16.5027 17.5718 16.3493 17.5718 15.912V11.8267C17.5718 11.3013 17.4251 11.068 17.0024 11.068H13.8731C13.5571 11.068 13.3664 11.312 13.3664 11.5427C13.3664 12.04 14.0838 12.1547 14.1584 13.5533V16.592C14.1584 17.2586 14.0424 17.3787 13.7891 17.3787C13.1131 17.3787 11.4718 14.8093 10.4984 11.8707C10.3064 11.2973 10.1158 11.0667 9.60644 11.0667Z"
      fill="white"
    />
  </svg>
)

export default memo(VKSVG)
