import React, { FC, memo } from 'react'

import { ISVGProps } from '@svg/types'

interface IProps extends ISVGProps {}

const YoutubeSVG: FC<IProps> = ({ className }) => (
  <svg
    className={className || ''}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#E52D27" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 17.967L14.7493 13.7059L18.8924 15.8439L14.75 17.967ZM23.8467 12.9677C23.8467 12.9677 23.6966 11.9227 23.2366 11.4626C22.6535 10.8586 22 10.8555 21.7005 10.8202C19.5549 10.6667 16.3365 10.6667 16.3365 10.6667H16.3292C16.3292 10.6667 13.1111 10.6667 10.9655 10.8202C10.666 10.8555 10.0125 10.8586 9.4294 11.4626C8.96939 11.9227 8.81995 12.9677 8.81995 12.9677C8.81995 12.9677 8.6665 14.1951 8.6665 15.4222V16.5732C8.6665 17.8006 8.81995 19.0273 8.81995 19.0273C8.81995 19.0273 8.96939 20.0724 9.4294 20.5328C10.0125 21.1368 10.7787 21.1175 11.12 21.181C12.3466 21.2974 16.3332 21.3334 16.3332 21.3334C16.3332 21.3334 19.5549 21.3283 21.7005 21.1752C22 21.1399 22.6535 21.1368 23.2366 20.5328C23.6966 20.0724 23.8467 19.0273 23.8467 19.0273C23.8467 19.0273 23.9998 17.8006 23.9998 16.5732V15.4222C23.9998 14.1951 23.8467 12.9677 23.8467 12.9677Z"
      fill="white"
    />
  </svg>
)

export default memo(YoutubeSVG)
