import { ICatalogList } from 'types/agency'

export const baseURL = '/api'
export const personalLink =
  'https://www.kufar.by/%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5_%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5.htm'
export const politicLink =
  'https://docs.google.com/document/u/1/d/e/2PACX-1vT2J2u0DSaZWgrNkI64d-0uNWAQK4Xo1j7h5uoQPv02bE2aLbu7HZ0I3eWhB2qnLQ/pub?utm_source=web&utm_medium=footer&utm_campaign=data_privacy'
export const mainLink = 'https://re.kufar.by/'

export const defaultCallModal = {
  isActive: false,
  idActive: '',
}

export const catalogLists: ICatalogList[] = [
  { name: 'agency', text: 'Агентства недвижимости' },
  { name: 'broker', text: 'Специалисты' },
]

export const pagesURI = {
  agency: 'agencies',
  broker: 'brokers',
}

export const promotionCount = 12
export const agencyCount = 25
export const brokerCount = 25

export const agencySEO = {
  title: (city: string) =>
    `Агентства недвижимости ${city}, выбрать агентство недвижимости для сделки - Куфар`,
  description: (city: string) =>
    `Куфар - база агентств недвижимости ${city}: информация об агентствах, объекты, сайты агентств, контакты.`,
  keywords: (city: string) =>
    `Агентства недвижимости ${city}, выбрать агентство недвижимости для сделки`,
}

export const brokerSEO = {
  title: (city: string, inCity: string) => `Риэлторы ${city}, найти риэлтора в ${inCity} – Куфар`,
  description: (city: string) =>
    `Куфар - база риэлторов ${city}: информация о риэлторах, объекты, контакты риэлторов.`,
  keywords: (city: string, inCity: string) => `Риэлторы ${city}, найти риэлтора в ${inCity}`,
}

export const discountSEO = {
  title: `Скидки от застройщиков и банков, новостройки акции – Куфар`,
  description: `Куфар - каталог скидок и акций от застройщиков и банков. Список скидок на новостройки, скидки на жилье.`,
  keywords: `Скидки, акции, новостройки`,
}

export const headers = {
  'X-Frame-Options': 'SAMEORIGIN',
  'X-Content-Type-Options': 'nosniff',
  'Referrer-Policy': 'strict-origin',
  'X-XSS-Protection': '1; mode=block',
  'Strict-Transport-Security': 'max-age=2592000; includeSubDomains',
  'Permission-Policy':
    'accelerometer=(), camera=(), fullscreen=(), geolocation=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), sync-xhr=(), usb=()',
  'Content-Security-Policy': `frame-ancestors 'self' *.kufar.by`,
}
