import React, { memo } from 'react'

import LogoSVG from '@svg/Logo'
import Typography from '@ui/typography'
import Container from '@ui/container'

import { linkMain, links } from './Header.constants'

import * as styles from './Header.module.scss'

const Header = () => (
  <div className={styles.header}>
    <Container>
      <div className={styles.content}>
        <div className={styles.logo}>
          <LogoSVG />
          <Typography type="h2_bold" component="h2" className={styles.name}>
            Недвижимость
          </Typography>
        </div>

        <nav className={styles.nav}>
          <ul className={styles.list}>
            {links.map(({ text, link }, index) => (
              <li className={styles.elem} key={index}>
                <a href={link} className={styles.link} target="_blank">
                  <Typography type="body_1">{text}</Typography>
                </a>
              </li>
            ))}
          </ul>
        </nav>

        <a href={linkMain} className={styles.linkMain}>
          <Typography type="h6_bold" className={styles.toMain}>
            На главную
          </Typography>
        </a>
      </div>
    </Container>
  </div>
)

export default memo(Header)
