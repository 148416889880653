import React, { FC, memo } from 'react'

import { ISVGProps } from '@svg/types'

interface IProps extends ISVGProps {}

const AppleStoreSVG: FC<IProps> = ({ className }) => (
  <svg
    className={className || ''}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1356 0C16.2875 1.37359 15.7401 2.72715 14.9401 3.72206C14.1052 4.70151 12.7697 5.45065 11.4689 5.35416C11.2986 4.03246 11.9577 2.62702 12.6955 1.76955C13.5295 0.79648 14.9776 0.0509747 16.1356 0ZM20.3939 7.92378C20.2487 8.00995 17.843 9.43775 17.8694 12.3496C17.9005 15.8659 20.9634 17.0283 21 17.0402C20.9817 17.1221 20.5203 18.7196 19.3715 20.338C18.4122 21.7653 17.4071 23.1608 15.8106 23.1844C15.0514 23.2023 14.5388 22.9853 14.0046 22.7592C13.4477 22.5235 12.8674 22.2778 11.9596 22.2778C10.9972 22.2778 10.3911 22.5316 9.80661 22.7763C9.30088 22.988 8.81137 23.193 8.12133 23.2208C6.60086 23.2764 5.43922 21.6989 4.4451 20.2843C2.45778 17.3979 0.910769 12.1502 2.98505 8.57928C3.99015 6.82793 5.82277 5.70011 7.78171 5.67008C8.64434 5.65254 9.4717 5.98312 10.197 6.27292C10.7516 6.49453 11.2466 6.6923 11.652 6.6923C12.0081 6.6923 12.4891 6.50244 13.0498 6.28116C13.9334 5.93243 15.0148 5.50564 16.1164 5.62092C16.8697 5.64277 19.0136 5.91676 20.3968 7.92207L20.3939 7.92378Z"
      fill="white"
    />
  </svg>
)

export default memo(AppleStoreSVG)
