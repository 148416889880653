import React, { FC, memo } from 'react'

import { ISVGProps } from '@svg/types'

interface IProps extends ISVGProps {}

const GoogleStoreSVG: FC<IProps> = ({ className }) => (
  <svg
    className={className || ''}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8149 10.5618C23.8109 11.1399 23.8109 12.5782 22.815 13.1564L19.1435 15.2876L15.7183 12.064L19.4127 8.5869L22.8149 10.5618ZM18.5102 8.06304L5.25304 0.367568C4.6464 0.0154305 3.94664 0.142429 3.48999 0.554963L14.9888 11.3773L18.5102 8.06304ZM14.2592 12.064L3.01119 1.47757C3.00381 1.53858 3 1.60105 3 1.66485V22.0537C3 22.2399 3.03252 22.4149 3.09126 22.575L14.2592 12.064ZM3.73399 23.3433L14.9888 12.7506L18.241 15.8115L5.25305 23.3509C4.74636 23.6451 4.1747 23.6049 3.73399 23.3433Z"
      fill="white"
    />
    <mask id="mask0_429_1935" maskUnits="userSpaceOnUse" x="3" y="0" width="21" height="24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8149 10.5618C23.8109 11.1399 23.8109 12.5782 22.815 13.1564L19.1435 15.2876L15.7183 12.064L19.4127 8.5869L22.8149 10.5618ZM18.5102 8.06304L5.25304 0.367568C4.6464 0.0154305 3.94664 0.142429 3.48999 0.554963L14.9888 11.3773L18.5102 8.06304ZM14.2592 12.064L3.01119 1.47757C3.00381 1.53858 3 1.60105 3 1.66485V22.0537C3 22.2399 3.03252 22.4149 3.09126 22.575L14.2592 12.064ZM3.73399 23.3433L14.9888 12.7506L18.241 15.8115L5.25305 23.3509C4.74636 23.6451 4.1747 23.6049 3.73399 23.3433Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_429_1935)"></g>
  </svg>
)

export default memo(GoogleStoreSVG)
