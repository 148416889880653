import { useEffect, useState } from 'react'

interface IWindowDimension {
  width: number
  height: number
}

function getWindowDimensions(): IWindowDimension {
  const isBrowser = typeof window !== "undefined"

  if(!isBrowser) return { width:  0, height: 0 }

  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimension>(getWindowDimensions())
  const [isDesktop, setIsDesktop] = useState<boolean>(false)
  const [isTablet, setIsTablet] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    const isBrowser = typeof window !== "undefined"

    isBrowser && window.addEventListener('resize', handleResize)
    return () => {
      isBrowser && window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const { width } = windowDimensions
    setIsDesktop(width > 1024)
    setIsTablet(width <= 1024 && width >= 768)
    setIsMobile(width < 768)
  }, [windowDimensions])

  return {
    isDesktop,
    isMobile,
    isTablet
  }
}

export default useWindowDimensions